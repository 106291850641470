* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "minion-pro", serif;
  font-weight: 700;
  font-style: normal;
  background-color: var(--mainColor);
}

:root {
  --mainColor: #EEEDEB;
  --secondary: #9dbeb7;
  --cta: #e73213;
  --textColor: #131617;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  margin: 2.8rem 2.8rem;
  color: var(--textColor);
}

.site-title {
  font-size: 3rem;
  color: var(--textColor);
  text-decoration: none;
  flex-grow: 1;
  letter-spacing: 1px;
}

nav a {
  color: var(--textColor);
  text-decoration: none;
  font-family: "letter-gothic-std", monospace;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem;
  transition-duration: 0.4s;
}

nav a:hover {
opacity: 50%;
}

.selected {
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-thickness: 2px;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  display: none;
  opacity: 0;
  font-size: 1.8rem;
}

header div, nav {
  display: flex;
  align-items: center;
}

.home-page {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5rem 4rem;
  margin-bottom: 2.5rem;
}

#hero-image {
  max-width: 40rem;
  margin-right: 2rem;
}

.main-text-container {
  display: flex;
  flex-direction: column;
  padding-right: 5rem;
}

.home-main-text {
  max-width: 40rem;
  font-size: 2.5rem;
  font-weight: 400;
}

#main-text-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  height: 4rem;
  max-width: 12rem;
  font-family: "letter-gothic-std", monospace;
  font-size: 1rem;
  font-weight: 700;
  background-color: var(--textColor);
  color: var(--mainColor);
  text-decoration: none;
  transition-duration: 0.4s;
  filter: drop-shadow(5px 5px 0 var(--cta));
}

#main-text-btn:hover {
  opacity: 50%;
}

@media only screen and (max-width: 1225px) {
  
  .home-main-text {
    font-size: 2.5rem;
  }

}

@media only screen and (max-width: 1024px) {

  header {
    margin: 0rem 2rem;

  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
    display: flex;
}

header nav {
  position: fixed;
  top: -100vh;
  left: 0;
  height: 100%;
  min-height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  background-color: var(--mainColor);
  transition: 1s;
}

header .responsive_nav {
  transform: translateY(100vh);
}

.nav-links {
  flex-direction: column;
}

.responsive_nav {
  flex-direction: column-reverse;
  z-index: 1;
}

  .site-title {
      font-size: 1.5rem;
      margin: 0;
      padding: 0;
  }

nav .nav-close-btn {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

nav a {
  font-size: 1.5rem;
}

  .home-page {
      flex-direction: column;
      margin: 0;
  }

  #hero-image {
      width: 80vw;
      margin: 0;
      margin-bottom: 2rem;
  }
  
  .main-text-container {
      width: 75vw;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .home-main-text {
      font-size: 1.5rem;
      text-align: center;
  }

  #main-text-btn {
      margin-top: 2rem;
      width: 9rem;
      height: 3rem;
      font-size: .8rem;
  }
}

/* Tour Dates */

.tour-date-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  margin-bottom: 5rem;
}

.tour-date-page > h1 {
  font-size: 2rem;
}

.tour-date-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  max-width: 75vw;
  border-bottom: 1px solid #2f2f2f;
  padding: 2rem;
  text-align: center;
}

.tour-links {
  text-decoration: none;
  color: var(--mainColor);
  background-color: var(--textColor);
  transition-duration: 0.4s;
}

.tour-links.is-empty {
  opacity: 20%;
  cursor: default;
}

.tour-links.is-empty:hover {
  opacity: 20%;
}

.tour-date-container > * {
  align-self: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 400;
}

.tour-links:hover {
  opacity: 50%;
}

@media only screen and (max-width: 850px) {
  .tour-date-container {
    grid-template-columns: 1fr 2fr 2fr 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    grid-gap: 1rem;
  }
  
  .tour-date-container > * {
    font-size: 1.5rem;
  }
  
  .tour-date-container #date {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 6;
    align-self: center;
  }
  
  .tour-date-container p:nth-of-type(2) {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 6;
    align-self: center;
  }
  
  .tour-date-container p:nth-of-type(3) {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 6;
    align-self: center;
  }
  
  .tour-date-container a:nth-of-type(1) {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 3;
    align-self: center;
  }
  
  .tour-date-container a:nth-of-type(2) {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 3;
    grid-column-end: 6;
    align-self: center;
  }
}

/* Contact */

.contact-page {
  margin-top: 2.5rem;
}

form > h1 {
  margin-bottom: 30px;
}

.form-container {
  border-radius: 10px;
  flex: 1;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

label {
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

  input[type="text"], input[type="email"], input[type="subject"], textarea {
  margin-top: 10px;
  width: 40rem;
  border: 1px solid var(--textColor);
  padding: 10px;
  font-size: 1.5rem;
  font-weight: 300;
}

textarea {
  resize: none;
  min-height: 10rem;
  font-weight: 300;
}

#submit-btn {
  background-color: var(--textColor);
  color: white;
  padding: 12px 20px;
  border: none;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
  transition-duration: 0.4s;
  width: 100%;
  max-width: 15rem;
  height: 5rem;
}

#submit-btn:hover {
  opacity: 50%;
}




@media only screen and (max-width: 705px) {

  .contact-page {
    margin-top: 0;
  }

  form > h1 {
    margin-bottom: 1rem;
  }

  label {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  input[type="text"], input[type="email"], input[type="subject"], textarea {
    margin-top: 5px;
    width: 20rem;
    height: 3rem;
    font-size: 1rem;
  }

  #submit-btn {
    font-size: 1rem;
    margin: 0;
    width: 100%;
    max-width: 10rem;
    height: 3rem;
    margin-bottom: 5rem;
  }

}

/* band */

.band-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem;
}

.band-page-text-container {
  width: 50rem;
  margin-right: 5rem;
}

.band-page-text-container > h1 {
  text-align: center;
  font-size: 2rem;
  padding-bottom: 2rem;
}

.band-page-text-container > p {
  font-size: 1.5rem;
  font-weight: 300;
  text-align: right;
}

#band-photo-1 {
  max-width: 50rem;
  width: 50vw;
}

@media only screen and (max-width: 1200px) {
  
  .band-page {
    flex-direction: column;
    margin: 0;
    margin-bottom: 5rem;
  }

  .band-page-text-container {
    width: 75vw;
    margin-right: 0;
    margin-bottom: 2.5rem;
  }

  .band-page-text-container > h2 {
    text-align: center;
    font-size: 2.5rem;
    padding-bottom: 2rem;
  }

  .band-page-text-container > p {
    text-align: center;
  }

  #band-photo-1 {
    width: 50vw;
    min-width: 20rem;
    margin: 0rem 2.5rem;
  }

}

/* Watch */

.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5rem;
}

iframe {
  height: 30vw;
  width: 50vw;
  margin-bottom: 5rem;
}

/* shop */

